import axios from 'axios';
import CryptoJS from 'crypto-js';

export default class facebookConversionsAPI {

    constructor(){

        this.pixelId = 358554613873443;
        this.accessToken = "EAAYLG9GxZAFQBO9ZAZCVhHxrlgw6ZCSy4HzCPS0MH10pdqliydUFkHB41RvEDI7fwRMT4oK0PzGLfbI19iASEaZBW8V7lxryjeMXknNuj8ZCnbn8wXphFY5iUbgy2HMmCkfDmncAQtSM43CQZB7CwEycD2ZAA65vsrZBE9lytmY7z6qQQe9BY5mZAZA84MdBhjURUF3YAZDZD";
        this.currentTimeInSeconds = Math.floor(Date.now() / 1000);
        this.eventSourceUrl = window.location.href;
        // this.testEventCode = 'TEST75907';

    }

    // 瀏覽商品時
    sendViewContent(userData, viewContentProductsData) {
        const { client_user_agent, client_ip_address, email, name, city, country, phone, birthday, gender } = userData;
        axios.post(`https://graph.facebook.com/v19.0/${this.pixelId}/events`, {
            data: [
                {
                    event_name: "ViewContent",
                    event_time: this.currentTimeInSeconds,
                    event_source_url: this.eventSourceUrl,
                    event_id: "event.id.ViewContent",
                    action_source: "website" ,
                    user_data: {
                        "client_user_agent": client_user_agent,
                        "client_ip_address": client_ip_address,
                        "em": hashValue(email), // Email
                        "fn": hashValue(name), // Name
                        "ct": hashValue(city), // City
                        "country": hashValue(country), // country
                        "ph": hashValue(phone), // Phone
                        "db": hashValue(birthday), // Birth
                        "ge": hashValue(gender), // Gender
                    },
                    custom_data: {
                        "contents": viewContentProductsData.contents.map(product => ({
                            "id": product.id,
                            "item_price": product.item_price,
                        })),
                        "currency": viewContentProductsData.currency,
                    }
                }
            ],
            // test_event_code: this.testEventCode,
            access_token: this.accessToken
        });
    }

    // 加入購物車時
    sendAddToCart(userData, addToCartProductData) {
        const { client_user_agent, client_ip_address, email, name, city, country, phone, birthday, gender } = userData;
        return axios.post(`https://graph.facebook.com/v19.0/${this.pixelId}/events`, {
            data: [
                {
                    event_name: "AddToCart",
                    event_time: this.currentTimeInSeconds,
                    event_source_url: this.eventSourceUrl,
                    event_id: "event.id.AddToCart",
                    action_source: "website",
                    user_data: {
                        "client_user_agent": client_user_agent,
                        "client_ip_address": client_ip_address,
                        "em": hashValue(email), // Email
                        "fn": hashValue(name), // Name
                        "ct": hashValue(city), // City
                        "country": hashValue(country), // country
                        "ph": hashValue(phone), // Phone
                        "db": hashValue(birthday), // Birth
                        "ge": hashValue(gender), // Gender
                    },
                    custom_data: {
                        "contents": addToCartProductData.contents.map(product => ({
                            "id": product.id,
                            "quantity": product.quantity,
                            "item_price": product.item_price,
                        })),
                        "currency": addToCartProductData.currency,
                    }
                }
            ],
            // test_event_code: this.testEventCode,
            access_token: this.accessToken
        });
    }

    // 新增訂購人資料時
    sendAddPaymentInfo(paymentUserData) {
        // const { em, fn, ph } = paymentUserData;
        axios.post(`https://graph.facebook.com/v19.0/${this.pixelId}/events`, {
            data: [
                {
                    event_name: "AddPaymentInfo",
                    event_time: this.currentTimeInSeconds,
                    event_source_url: this.eventSourceUrl,
                    event_id: "event.id.AddPaymentInfo",
                    action_source: "website" ,
                    user_data: {
                        "em": hashValue(paymentUserData.em), // Email
                        "fn": hashValue(paymentUserData.fn), // Name
                        "ph": hashValue(paymentUserData.ph), // Phone
                    },
                }
            ],
            // test_event_code: this.testEventCode,
            access_token: this.accessToken
        });
    }

    // 點結帳時獲取訂購人資料 & 購物車內容
    sendInitiateCheckout(initiateCheckoutData) {
        axios.post(`https://graph.facebook.com/v19.0/${this.pixelId}/events`, {
            data: [
                {
                    event_name: "InitiateCheckout",
                    event_time: this.currentTimeInSeconds,
                    event_source_url: this.eventSourceUrl,
                    event_id: "event.id.InitiateCheckout",
                    action_source: "website" ,
                    user_data: {
                        "em": hashValue(initiateCheckoutData.em), // Email
                        "fn": hashValue(initiateCheckoutData.fn), // Name
                        "ph": hashValue(initiateCheckoutData.ph), // Phone
                    },
                    custom_data: {
                        "contents": initiateCheckoutData.contents.map(product => ({
                            "id": product.id,
                            "quantity": product.quantity,
                            "item_price": product.item_price,
                        })),
                        "currency": initiateCheckoutData.currency,
                        "delivery_category": initiateCheckoutData.delivery_category,
                    },
                }
            ],
            // test_event_code: this.testEventCode,
            access_token: this.accessToken
        });
    }

    // 結帳/訂單成立後
    sendPurchase(purchaseData) {
        axios.post(`https://graph.facebook.com/v19.0/${this.pixelId}/events`, {
            data: [
                {
                    event_name: "Purchase",
                    event_time: this.currentTimeInSeconds,
                    event_source_url: this.eventSourceUrl,
                    event_id: "event.id.Purchase",
                    action_source: "website" ,
                    user_data: {
                        "fn": hashValue(purchaseData.fn), // Name
                        "ph": hashValue(purchaseData.ph), // Phone
                    },
                    custom_data: {
                        "contents": purchaseData.contents.map(product => ({
                            "id": product.id,
                            "quantity": product.quantity,
                            "item_price": product.item_price,
                        })),
                        "value": purchaseData.value,
                        "currency": purchaseData.currency,
                        "delivery_category": purchaseData.delivery_category,
                    }
                }
            ],
            // test_event_code: this.testEventCode,
            access_token: this.accessToken
        });
    }

};

// 雜湊
function hashValue(value) {
    return CryptoJS.SHA256(value).toString();
}
